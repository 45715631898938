
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject } from 'vue';
import { Input, Image } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_MANUFACTURER } from '@/modules/catalog/manufacturers/graphql/Queriers';
import { UPDATE_MANUFACTURER } from '@/modules/catalog/manufacturers/graphql/Mutations';
import { EditManufacturerForm } from '@/modules/catalog/manufacturers/interfaces';
import { useI18n } from 'vue-i18n';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'edit manufacturer',
    components: {
        Input,
        Image,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const editManufacturerForm = ref<null | HTMLFormElement>(null);
        const i18n = useI18n();
        const previewImage = ref([]) as Record<any, any>;
        const store = useStore();
        const image = ref(null);

        // Given Add Manufacturer Form Interface
        const editManufacturerData = ref<EditManufacturerForm>({
            id: null,
            name: '',
            description: ''
        });

        // Submit Hanlder Request
        const submitHandlerForm = async () => {
            editManufacturerForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        id: editManufacturerData.value.id,
                        name: editManufacturerData.value.name,
                        description: editManufacturerData.value.description,
                        image: image.value
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_MANUFACTURER,
                        variables: { input: formData },
                        update: (store, { data: { update_manufacturer } }) => {
                            // const pervious_record = store.readQuery({
                            // 	query: GET_MANUFACTURER,
                            // 	variables: {
                            // 		page: current_page.value,
                            // 		limit: 10,
                            // 	},
                            // }) as Record<any, any>;
                            // store.writeQuery({
                            // 	query: GET_MANUFACTURER,
                            // 	variables: {
                            // 		page: current_page.value,
                            // 		limit: 10,
                            // 	},
                            // 	data: {
                            // 		manufacturers: {
                            // 			...pervious_record.manufacturers,
                            // 		},
                            // 		variables: {
                            // 			id: update_manufacturer.id,
                            // 		},
                            // 	},
                            // });
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        // On change image
        const handleChange = async (event: Record<any, any>) => {
            loading.value = true;
            const media = event.target.files;
            const response = await store.dispatch(Actions.CREATE_MEDIA, media);
            const images = JSON.parse(response);
            images.forEach(element => {
                previewImage.value.push({ src: element.asset_path, id: element.id });
                image.value = element.id;
            });
            loading.value = false;
        };

        // Remove Media Function
        const removeMedia = (index: number, media_id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loading.value = true;
                    const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
                    if (resp == 'true') {
                        previewImage.value.splice(index, 1);
                        Notify.success(i18n.t('message.MEDIA_REMOVED_SUCCESSFULLY'));
                        image.value = null;
                    }
                    loading.value = false;
                })
                .catch(() => {
                    loading.value = false;
                });
        };

        // Show Media
        const showMedia = async (num: number) => {
            const formData = {
                previewImage: previewImage.value,
                visible: true,
                index: num
            };
            await store.dispatch(Actions.TRIGGER_ACTION, { formData: formData, type: 'imageProps' });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            emitter.emit('clearInput');
            current_page.value = 1;
            previewImage.value = [];
            editManufacturerForm.value?.resetFields();
        };

        // Emitter To Open Model
        emitter.on('editManufacturerAction', (manufacturer: Record<any, any>) => {
            resetForm();
            current_page.value = manufacturer.current_page;
            editManufacturerData.value.id = manufacturer.id;
            editManufacturerData.value.name = manufacturer.name;
            editManufacturerData.value.description = manufacturer.description;

            if (manufacturer.image) {
                previewImage.value.push({
                    id: manufacturer.image.id,
                    src: manufacturer.image.file_path
                });
            }

            modal.value = new Modal(document.getElementById('modal_edit_manufacturer')) as HTMLElement;
            modal.value.show();
        });

        return {
            loading,
            editManufacturerData,
            editManufacturerForm,
            previewImage,
            handleChange,
            removeMedia,
            showMedia,
            submitHandlerForm,
            image
        };
    }
});
